import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";

import Layout from "../components/common/Layout";
import SliceZone from "../components/common/SliceZone";

const Page = ({ data }) => {
  if (!data) return null;

  const {
    title,
    title_background: titleBg,
    body: slices,
  } = data.prismicPage.data;

  const hasBg = titleBg?.gatsbyImageData;

  return (
    <Layout>
      <header
        className={`position-relative${hasBg ? " mb-6" : ""} text-center`}
      >
        <Container>
          <h1 className={`py-6${hasBg ? " py-lg-8" : ""} text-primary`}>
            {title}
          </h1>
        </Container>
        {hasBg && (
          <GatsbyImage
            image={titleBg.gatsbyImageData}
            alt={titleBg.alt || ""}
            className="gatsby-background-image-element bg-light"
          />
        )}
      </header>
      <SliceZone slices={slices} />
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      ...prismicPageFragment
    }
  }
`;

export default Page;
